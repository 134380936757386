// src/components/Home.js
import React from 'react';

const ShippingDetails = () => {
  return (
    <div id="main">
      <div className="inner">
          <h2>Shipping Information</h2>
          <p style={{fontSize:15}}>Find all the details about our eco-friendly and reliable shipping process for your packaging needs.</p>
          <section>
            <p>
              <span class="image main"><img src="images/shipping1.png" alt="" /></span>
              <h2>Shipping Methods</h2>
              <span class="image left"><img src="images/ship2.png" alt="" /></span>
              At Annai exports & imports, we pride ourselves on offering a variety of shipping methods to suit your needs. Whether you require quick delivery or are more focused on cost-efficiency, we have a solution for you. Our eco-friendly packaging options are designed to minimise environmental impact while ensuring your products arrive safely. We partner with trusted carriers to provide reliable and timely deliveries. <br />For bulk orders, we offer specialised shipping solutions to accommodate larger quantities without compromising on speed or safety. Subject to export regulations, we will provide you with the necessary capital goods in accordance with your company's wishes in a timely manner, with world-class certification, in a way that does not harm the environment. We are committed to working with your company with confidence and commitment. And our company is about to take part in the production of materials for many new types of packaging.
              <br /><br /><br /><br />
            </p>
            <p>
              <h2>Eco-Friendly Packaging Solutions</h2>
              <span class="image right"><img src="images/green.png" alt="" /></span>
              Our commitment to sustainability extends to our shipping processes. All our packaging materials are eco-friendly, recyclable, and sourced responsibly. We believe in reducing our carbon footprint and are continuously looking for innovative ways to enhance the eco-friendliness of our operations. Using sustainable materials doesn't compromise the quality or durability of our packaging. Our clients can rest assured that their products are protected while also supporting environmental sustainability. Our products are produced and exported in a framework that allows us to obtain certifications in global standards in accordance with federal resource classifications from forest stewardships. Not only the certificates of the packaging materials, We guarantee that our production will always stand by you with important responsibilities for the benefit of the environment.
              <br /><br />
            </p>
            <p>
              <br/><br/>
              <h2>Custom and Bulk Packaging Options</h2>
              <span class="image left"><img src="images/bulk.png" alt="" /></span>
              Annai exports & imports understands that every business has unique needs when it comes to packaging and shipping. That's why we offer custom packaging solutions tailored to your specific requirements. Whether it's the size, shape, or material, our team works closely with you to create the perfect packaging for your products. For businesses that require large quantities, our bulk packaging supplies provide cost-effective options without sacrificing quality. Our efficient shipping processes ensure that even the largest orders are handled with care and delivered promptly.              
              <br /><br /><br /><br /><br /><br />
            </p>
          </section>
          
      </div>
    </div>
  );
}

export default ShippingDetails;