// src/components/Home.js
import React from 'react';

const Header = ({ toggleMenu }) => {
	
  return (
    <>
      	<header id="header">
			<div className="inner">
				<a href="/" className="logo">
					<span className="symbol"><img src="images/logo2.png" alt="" /></span>
					<span className="title"><h1>Annai Exports & Imports</h1></span>
				</a>
				<nav>
					<ul>
						<li><a href="#menu" onClick={toggleMenu}>Menu Test</a></li>
					</ul>
				</nav>
			</div>
		</header>
    </>
  );
}

export default Header;
