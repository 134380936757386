import AboutUs from "./components/aboutus";
import Footer from "./components/footer";
import Header from "./components/header";
import Home from "./components/Home";
import Menu from "./components/menu";
import EcoPacking from "./components/eco-packing";
import ProductDetails from "./components/product-info";
import Product from "./components/products";
import { Routes, Route } from "react-router-dom";
import ShippingDetails from "./components/shipping-info";
import React, { useState, useEffect } from 'react';
import ScrollToTopButton from './components/scroll-to-top';

function App() {

const [isMenuOpen, setMenuOpen] = useState(false);

const toggleMenu = () => {
	setMenuOpen(!isMenuOpen);
};

useEffect(() => {
    const rootElement = document.getElementById('root');
    if (isMenuOpen) {
      rootElement.classList.add('is-menu-visible');
    } else {
      rootElement.classList.remove('is-menu-visible');
    }
  }, [isMenuOpen]);

return (
	<>
	<div id="wrapper">
		<Header toggleMenu={toggleMenu} />
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/AboutUs" element={<AboutUs />} />
			<Route path="/Products" element={<Product />} />
			<Route path="/Shipping" element={<ShippingDetails />} />
			<Route path="/ProductDetails" element={<ProductDetails />} />
			<Route path="/EcoPacking" element={<EcoPacking />} />
		</Routes>
		<Footer />
		<ScrollToTopButton />
	</div>
	<Menu isOpen={isMenuOpen} setIsOpen={setMenuOpen} toggleMenu={toggleMenu} />
  	</>
  );
}

export default App;
