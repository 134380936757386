import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const Menu = ({ isOpen, setIsOpen, toggleMenu }) => {

  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the menu
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      // Clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  
  return (
    <div id="menu" ref={menuRef} style={{ display: isOpen ? 'block' : 'none' }}>
      <div class="inner">
      <h2>Menu</h2>
      <ul>
        <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
        <li><Link to="/EcoPacking" onClick={toggleMenu}>Our Eco-Friendly Packing</Link></li>
        <li><Link to="/Shipping" onClick={toggleMenu}>Shipping information</Link></li>
        <li><Link to="/Products" onClick={toggleMenu}>Products</Link></li>
        <li><Link to="/AboutUs" onClick={toggleMenu}>About Us</Link></li>
      </ul>
    </div>
    <a class="close" href="#menu" onClick={toggleMenu}>Close</a>
  </div>
  );
};

export default Menu;
