// src/services/emailService.js

import axios from 'axios';

const apiEndpoint = 'https://xiubveo2n5.execute-api.ap-south-1.amazonaws.com';

export const sendEmail = async (formData) => {
  try {
    const response = await axios.post(apiEndpoint+'/sendemail', formData);
    return response;
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};

export const getProducts = async () => {
  try {
    const response = await axios.get(apiEndpoint+'/products');
    return response.data;
  } catch (error) {
    console.error('Error get products:', error);
    throw error;
  }
};