import React from 'react';

const EcoPacking = () => {
  return (
    <div id="main">
        <div class="inner">
          <section>
            <p>
              <span class="image main"><img src="images/eco-title1.png" alt="" /></span>
              <h2>Eco-friendly Packaging</h2>
              <span class="image left"><img src="images/eco1.jpg" alt="" /></span>
              Our Eco-friendly Packaging solutions are designed to minimise environmental impact without sacrificing quality or functionality. We use recyclable, biodegradable, and compostable materials that are sourced responsibly. These materials not only help reduce waste but also ensure that your products are protected and presented beautifully. By choosing our eco-friendly options, you contribute to a greener, more sustainable future.
              <p>Choosing our Eco-friendly Packaging means you are investing in sustainable practices. Our materials are carefully selected to ensure they have minimal impact on the environment while still providing excellent protection and presentation for your products. Join us in making a positive impact on the planet.</p>
              <ul>
                <li>Recyclable materials</li>
                <li>Biodegradable materials</li>
                <li>Compostable materials</li>
              </ul>
            </p>
            <p>
              <h2>Custom Packaging Solutions</h2>
              <span class="image right"><img src="images/eco2.jpg" alt="" /></span>
              at Annai exports & imports provide tailored solutions that meet your specific needs. Whether you require unique shapes, sizes, or material specifications, we work closely with you to create packaging that aligns with your brand and product requirements. Our team of experienced designers and engineers uses the latest technology to produce prototypes quickly and efficiently, ensuring that your custom packaging is both innovative and practical.
              <p>
              With our Custom Packaging Solutions, you get packaging that is not only unique but also perfectly suited to your needs. Our team collaborates with you to bring your vision to life, using the latest technology to ensure high-quality results. Trust us to deliver packaging that enhances your brand.
              </p>
              <ul>
                <li>Tailored solutions</li>
                <li>Innovative designs</li>
                <li>Quick prototypes</li>
              </ul>
            </p>
            <p>
              <h2>Bulk Packaging Supplies</h2>
              <span class="image left"><img src="images/eco4.webp" alt="" /></span>
              are ideal for businesses looking to purchase large quantities of packaging materials at competitive prices. We offer a wide range of eco-friendly bulk options, including boxes, bags, and wrapping materials. Our bulk supplies are designed to cater to various industries, ensuring that you have the right packaging for your products. By buying in bulk, you not only save money but also reduce your carbon footprint through fewer shipments and less overall packaging waste.
              <p>
              Our Bulk Packaging Supplies are perfect for businesses looking to save money while staying eco-conscious. By purchasing in bulk, you reduce the frequency of shipments and the overall packaging waste. We offer a variety of eco-friendly options to suit different industries and needs.
              </p>
              <ul>
                <li>Competitive pricing</li>
                <li>Wide range of options</li>
                <li>Reduced carbon footprint</li>
              </ul>
            </p>
          </section>
        </div>
    </div>
  );
};

export default EcoPacking;
