import React, { useState, useRef, useEffect, useCallback } from 'react';
import { getProducts } from '../services/api-services';
import Loader from './loader';

const Product = () => {
	const [data, setData] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [loading, setLoading] = useState(true);
	const modalRef = useRef(null);

	const openModal = (item) => {
		setSelectedItem(item);
		setIsModalOpen(true);
	};

	const closeModal = useCallback(() => {
		setSelectedItem(null);
		setIsModalOpen(false);
	}, []);

	const handleClickOutside = useCallback((event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			closeModal();
		}
	}, [closeModal]);

	useEffect(() => {
		if(data.length) return;
		const fetchData = async () => {
		  const products = await getProducts();
		  setData(products);
		  setLoading(false);
		};
		fetchData();
	  });

	useEffect(() => {
		if (isModalOpen) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isModalOpen, handleClickOutside]);

	if (loading) {
		return <Loader />;
	}

	return (
		<div id="main">
			<div className="inner">
				<section className="tiles">
					{data.map((item, index) => {
						const styleClass = `style${(index % 6) + 1}`;

						return (
							<article className={styleClass} key={index}>
								<span className="image" style={{ cursor: 'pointer' }}>
									<img src={item.image} alt={item.title} />
								</span>
								<a href={item.link} onClick={(e) => { e.preventDefault(); openModal(item); }}>
									<h2>{item.title}</h2>
								</a>
							</article>
						);
					})}

					{isModalOpen && selectedItem && (
						<div className="custom-modal">
							<div className="modal-content" ref={modalRef}>
								<span className="close-button" onClick={closeModal}>&times;</span>
								<img src={selectedItem.image} alt={selectedItem.title} className="modal-image" />
								<div className="text-container">
									<h2 className="modal-title">{selectedItem.title}</h2>
									<p className="modal-description">{selectedItem.description}</p>
								</div>
							</div>
						</div>
					)}
				</section>
			</div>
		</div>
	);
}

export default Product;