// src/components/Home.js
import React from 'react';

const Home = () => {
  return (
    <div id="main">
		<div className="inner">
			<header>
			<h2>Eco-friendly Packaging Solutions for a Greener Tomorrow
			<br /></h2>
          	<p>Welcome to Annai Exports & Imports, your premier choice for sustainable and high-quality packaging solutions, proudly based in Coventry, UK. We specialize in providing eco-friendly packaging options that not only meet but exceed industry standards.</p>
			</header>
			<div>
				<br/>
				<div style={{textAlign: 'center'}}>
					<h2>Our Eco-Friendly Packaging</h2>
				</div>
				<br/>
				<div>
					<section className="tiles">
						<article className="style1">
							<span className="image">
								<img src="images/eco1.jpg" alt="" />
							</span>
							<a>
								<h2>Eco-friendly Packaging</h2>
							</a>
							<br/>
							<div className="content">
								<p>Our Eco-friendly Packaging uses recyclable and biodegradable materials, promoting sustainability and quality.</p>
							</div>
						</article>
						<article className="style2">
							<span className="image">
								<img src="images/eco2.jpg" alt="" />
							</span>
							<a>
								<h2>Custom Packaging Solutions</h2>
							</a>
							<br/>
							<div className="content">
								<p>Custom Packaging Solutions tailored to your brand with innovative designs and practical prototypes.</p>
							</div>
						</article>
						<article className="style3">
							<span className="image">
								<img src="images/eco4.webp" alt="" />
							</span>
							<a>
								<h2>Bulk Packaging Supplies</h2>
							</a>
							<br/>
							<div className="content">
								<p>Bulk Packaging Supplies offer cost-effective, eco-friendly materials in large quantities to reduce waste.</p>
							</div>
						</article>
					</section>
				</div>

			</div>
			
			
		</div>
	</div>
  );
}

export default Home;