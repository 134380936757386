// src/components/Home.js
import React from 'react';

const AboutUs = () => {
  return (
    <div id="main">
      <div className="inner">
        <header>
        <h2>
              About Us
          </h2>
         
          <p>
          Annai Exports & Imports is a Coventry-based company that specialises in providing eco-friendly packaging solutions. Our mission is to offer sustainable and high-quality packaging options that meet the diverse needs of our clients. We are dedicated to reducing environmental impact through the use of recycled and biodegradable materials. Our team works closely with clients to deliver customised packaging solutions that reflect their brand and protect their products. With a focus on sustainability, quality, and customer satisfaction, we strive to be a leader in the packaging industry. Whether you need eco-friendly packaging, custom designs, or bulk supplies, Annai Exports & Imports has the right solution for you.
          </p>

        </header>
      </div>
    </div>
  );
}

export default AboutUs;